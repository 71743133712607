<template>
  <div class="rule-history-drawer">
    <el-drawer
      title="版本详情"
      :visible.sync="show"
      size="85%"
      :before-close="
        () => {
          $emit('close');
        }
      "
    >
      <ruleDetail v-if="show" :dataset="dataset" source="dataset" />
    </el-drawer>
  </div>
</template>

<script>
import ruleDetail from "../../detail/index.vue";
export default {
  name: "ruleHistoryDrawer",
  components: {
    ruleDetail,
  },
  props: {
    show: {
      type: Boolean,
      default() {
        return false;
      },
    },
    dataset: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  methods: {},
};
</script>

<style lang="less" scoped>
.rule-history-drawer {
}
</style>