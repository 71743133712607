var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "release-history-component app-container" },
    [
      _c(
        "div",
        [
          _c("FinalTable", {
            ref: "finalTableRef",
            attrs: { datas: _vm.dataset },
            on: { tableEventHandler: _vm.tableEventHandler },
            scopedSlots: _vm._u([
              {
                key: "lastUpdateDateSlot",
                fn: function () {
                  return [
                    _c("el-date-picker", {
                      staticStyle: { width: "100%" },
                      attrs: {
                        type: "datetimerange",
                        "value-format": "timestamp",
                        "range-separator": "至",
                        "start-placeholder": "开始日期",
                        "end-placeholder": "结束日期",
                      },
                      on: { change: _vm.timeRangeChange },
                      model: {
                        value: _vm.timeRange,
                        callback: function ($$v) {
                          _vm.timeRange = $$v
                        },
                        expression: "timeRange",
                      },
                    }),
                  ]
                },
                proxy: true,
              },
            ]),
          }),
        ],
        1
      ),
      _c("RuleHistoryDrawer", {
        attrs: { show: _vm.detailDrawer.show, dataset: _vm.detailDrawer.datas },
        on: { close: _vm.ruleHistoryDrawerClose },
      }),
      _vm.triggerDrawer.show
        ? _c("TriggerHistoryDrawer", {
            attrs: {
              show: _vm.triggerDrawer.show,
              dataset: _vm.triggerDrawer.datas,
            },
            on: { close: _vm.triggerDrawerClose },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }