var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "rule-history-drawer" },
    [
      _c(
        "el-drawer",
        {
          attrs: {
            title: "版本详情",
            visible: _vm.show,
            size: "85%",
            "before-close": () => {
              _vm.$emit("close")
            },
          },
          on: {
            "update:visible": function ($event) {
              _vm.show = $event
            },
          },
        },
        [
          _vm.show
            ? _c("ruleDetail", {
                attrs: { dataset: _vm.dataset, source: "dataset" },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }