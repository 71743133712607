<template>
  <div class="release-history-component app-container">
    <div>
      <FinalTable
        ref="finalTableRef"
        :datas="dataset"
        @tableEventHandler="tableEventHandler"
      >
        <template v-slot:lastUpdateDateSlot>
          <el-date-picker
            v-model="timeRange"
            type="datetimerange"
            value-format="timestamp"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            style="width: 100%"
            @change="timeRangeChange"
          >
          </el-date-picker>
        </template>
      </FinalTable>
    </div>
    <!-- 规则配置抽屉 -->
    <RuleHistoryDrawer
      :show="detailDrawer.show"
      :dataset="detailDrawer.datas"
      @close="ruleHistoryDrawerClose"
    />
    <!-- 触发记录抽屉 -->
    <TriggerHistoryDrawer
      v-if="triggerDrawer.show"
      :show="triggerDrawer.show"
      :dataset="triggerDrawer.datas"
      @close="triggerDrawerClose"
    />
  </div>
</template>

<script>
import FinalTable from "@/components/FinalTable";
import RuleHistoryDrawer from "./components/ruleHistoryDrawer.vue";
import TriggerHistoryDrawer from "./components/triggerHistoryDrawer.vue";
import { getRuleReleaseHistory } from "@/api/ruge/vlink/rule/rule";
export default {
  name: "releaseHistoryComponent",
  components: {
    FinalTable,
    RuleHistoryDrawer,
    TriggerHistoryDrawer,
  },
  data() {
    return {
      timeRange: [],
      tableLoading: false,
      currentKey: "",
      listQuery: {
        current: 1,
        rowCount: 10,
        startTime: null,
        endTime: null,
      },
      dataset: {
        paginationConfig: {
          need: true,
        },
        filterLine: {
          show: true,
        },
        selection: {
          need: false,
        },
        header: [
          {
            prop: "ruleName",
            label: this.$t("vlink.rule.ruleName"),
            width: "",
          },
          { prop: "ruleKey", label: this.$t("vlink.rule.ruleKey"), width: "" },
          {
            prop: "ruleVersion",
            label: "规则版本",
            width: "",
          },
          {
            prop: "ruleScope",
            label: this.$t("vlink.rule.ruleScope"),
            width: "",
          },
          {
            prop: "ruleType",
            label: this.$t("vlink.rule.ruleType"),
            width: "",
          },
          // {
          //   prop: "ruleStatus",
          //   label: this.$t("vlink.rule.ruleStatus"),
          //   width: "",
          // },
          {
            prop: "updateDate",
            label: "创建时间",
            width: "370",
          },
          { prop: "operation", label: "操作", width: "160" },
        ],
        tableData: [],
        // 搜索行配置
        searchLineConfig: {
          updateDate: {
            type: "slot",
            slotName: "lastUpdateDateSlot",
          },
          ruleName: {
            type: "input",
            label: "规则名称",
            value: "",
            actionType: "goSearch",
            placeholder: "请输入规则名称",
            prefixIcon: "el-icon-search",
          },
          // ruleKey: {
          //   type: "input",
          //   label: "规则标识码",
          //   value: "",
          //   actionType: "goSearch",
          //   placeholder: "请输入规则标识码",
          //   prefixIcon: "el-icon-search",
          // },
          ruleScope: {
            type: "selector",
            label: "作用域",
            value: "",
            placeholder: "请选择作用域",
            actionType: "goSearch",
            optionList: [
              {
                label: "云端",
                value: "CLOUD",
              },
              {
                label: "边缘",
                value: "EDGE",
              },
            ],
          },
          ruleType: {
            type: "selector",
            label: "规则类型",
            actionType: "goSearch",
            value: "",
            placeholder: "请选择设规则类型",
            optionList: [
              {
                label: "联动规则",
                value: "linkage",
              },
              {
                label: "告警规则",
                value: "alarm",
              },
              {
                label: "时间表",
                value: "schedule",
              },
              {
                label: "数据流转",
                value: "serverSub",
              },
              {
                label: "设备通信",
                value: "deviceCall",
              },
            ],
          },
          // ruleStatus: {
          //   type: "selector",
          //   label: "规则状态",
          //   actionType: "goSearch",
          //   value: "",
          //   placeholder: "请选择设规则状态",
          //   optionList: [
          //     {
          //       label: "启用",
          //       value: "ENABLE",
          //     },
          //     {
          //       label: "禁用",
          //       value: "DISABLE",
          //     },
          //   ],
          // },
          // operation: {
          //   type: "button",
          //   filterCount: "",
          //   actionType: "clickEvent",
          //   eventName: "showMoreFilters",
          //   label: "更多筛选",
          // },
        },
        // 表格内容配置
        detailConfig: {
          ruleScope: {
            type: "enumerationColumn",
            emuList: {
              CLOUD: "云端",
              EDGE: "边缘",
            },
          },
          ruleType: {
            type: "enumerationColumn",
            emuList: {
              linkage: "联动规则",
              alarm: "告警规则",
              schedule: "时间表",
              serverSub: "数据流转",
              deviceCall: "设备通信",
            },
          },
          // ruleStatus: {
          //   type: "enumerationColumn",
          //   emuList: {
          //     ENABLE: "启用",
          //     DISABLE: "禁用",
          //   },
          // },
          updateDate: {
            type: "dateFormat",
          },
          operation: {
            type: "icon",
            iconList: [
              {
                actionType: "iconClick",
                eventName: "ruleDetail",
                fontSize: "14px",
                color: "#1A4CEC",
                cursorPointer: "pointer",
                iconName: "el-icon-view",
                tooltips: "规则配置",
              },
              {
                actionType: "iconClick",
                eventName: "triggerList",
                fontSize: "14px",
                color: "#1A4CEC",
                cursorPointer: "pointer",
                iconName: "el-icon-view",
                tooltips: "触发记录",
              },
            ],
          },
        },
        // 高级搜索配置
        advanceFilterConfig: {
          productKey: {
            type: "slot",
            slotName: "productKeySlot",
            value: [],
            label: "产品",
          },
          deviceName: {
            type: "slot",
            slotName: "deviceNameSlot",
            value: [],
            label: "设备",
          },
          ruleName: {
            inline: true,
            value: "",
          },
          ruleScope: {
            inline: true,
            value: "",
          },
          ruleType: {
            inline: true,
            value: "",
          },
          // ruleStatus: {
          //   inline: true,
          //   value: "",
          // },
          creationDate: {
            type: "dateRange",
            label: "创建时间",
            value: [],
          },
        },
        pageVO: {
          current: 1,
          rowCount: 10,
          total: 0,
        },
      },
      detailDrawer: {
        show: false,
        datas: {},
      },
      triggerDrawer: {
        show: false,
        datas: {},
      },
    };
  },
  watch: {
    tableLoading: {
      handler(val) {
        this.$refs.finalTableRef.loadingToggle(val);
      },
    },
  },
  created() {
    const { ruleKey } = this.$route.query;
    if (!ruleKey) return;
    this.currentKey = ruleKey;
    this.getTableList();
  },
  methods: {
    timeRangeChange() {
      this.listQuery.startTime = this.timeRange ? this.timeRange[0] : null;
      this.listQuery.endTime = this.timeRange ? this.timeRange[1] : null;
      this.listQuery.current = 1;
      this.getTableList();
    },
    getTableList() {
      this.tableLoading = true;
      getRuleReleaseHistory({
        ...this.listQuery,
        ...{
          ruleKey: this.currentKey,
        },
      })
        .then((response) => {
          this.dataset.pageVO.total = response.total;
          this.dataset.tableData = response.rows || [];
        })
        .finally(() => {
          this.tableLoading = false;
        });
    },
    tableEventHandler(datas) {
      console.log("datas", datas);
      if (datas.type === "goSearch") {
        this.listQuery = { ...this.listQuery, ...datas.params };
        this.listQuery.current = 1;
        this.dataset.pageVO.current = 1;
        this.getTableList();
      } else if (datas.type === "paginationChange") {
        this.listQuery.current = datas.params.current.page;
        this.listQuery.rowCount = datas.params.current.limit;
        this.getTableList();
      } else if (datas.type === "switchEvent") {
        this.updateRuleStatus(datas.row);
      } else if (datas.type === "iconClick") {
        switch (datas.eventName) {
          case "ruleDetail":
            // this.jumpToDetail("view", datas.row);
            this.detailDrawer.datas = datas.row;
            this.detailDrawer.show = true;
            break;
          case "triggerList":
            this.triggerDrawer.datas = datas.row;
            this.triggerDrawer.show = true;
            break;
        }
      }
    },
    triggerDrawerClose() {
      this.triggerDrawer.datas = {};
      this.triggerDrawer.show = false;
    },
    ruleHistoryDrawerClose() {
      this.detailDrawer.datas = {};
      this.detailDrawer.show = false;
    },
  },
};
</script>

<style lang="less" scoped>
.release-history-component {
}
</style>